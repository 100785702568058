import React, {FunctionComponent, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faFileCsv, faFileExcel, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {useAppDispatch, useAppSelector} from '../../../../Config/Hooks';
import {RootState} from '../../../../Config/Store';
import {getEntities, reset, TemperatureMeasurement} from './TransformerTemperatureReducer';
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {
    ACard,
    AChartX,
    FloatMenuItem,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    XAxis
} from "@atiautomacao/ati-ui-library";
import {format} from "date-fns";
import {useSnackbar} from "notistack";
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {downloadFilePostMapping} from "../../../../Utils/DonwloadUtilsForPostMapping";
import {hasPermission} from "../../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../../Config/Constants";
import {useTheme} from "@mui/material";

export interface SeriesOption {
    name: string;
    type: string;
    showSymbol?: boolean;
    smooth?: boolean;
    data: any[];
}

const TransformerTemperatureChart: FunctionComponent<{powerPlant: number, skidId: number}> = ({powerPlant, skidId}) => {
    const theme = useTheme();
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_TRANSFORMER_TEMPERATURE, AUTHORITIES.CSV_EXPORT_TRANSFORMER_TEMPERATURE
    ]);
    const isAuthorizedToSearchByExcel = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_TRANSFORMER_TEMPERATURE, AUTHORITIES.EXCEL_EXPORT_TRANSFORMER_TEMPERATURE
    ]);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const entities:Array<TemperatureMeasurement> = useAppSelector((state: RootState) => state.transformerTemperatureChart.entities);
    const { loading, errorMessage } = useAppSelector((state: RootState) => state.transformerTemperatureChart);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const [showGraph, setShowGraph] = useState(false);

    // Setting range start period
    const initialPeriod : any = {
        groupBy : "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const [equipment, setEquipment] = useState<Array<TemperatureMeasurement>>(new Array<TemperatureMeasurement>)
    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [series, setSeries] = useState<Array<SeriesOption>>(new Array<SeriesOption>);

    const callback = (params:any) => {
        let formatDate = period && period.groupBy.valueOf() === "week" ? 'dd/MM HH:mm' : 'HH:mm'
        let dateStr = format(new Date(params[0].axisValue), formatDate);

        let result = dateStr + '<br/>';
        params.forEach(function (item: any) {
            result += item.marker + " " + item.seriesName + "&nbsp;&nbsp;&nbsp;&nbsp;" + "<strong>" + item.value[1] + " " + "°C" +"</strong>" + "<br/>";
        });

        return result;
    }
    const isDay = ()=>{
        return period && period.groupBy.valueOf() === "day"
    }
    const sortDataArray = (dataArray:Array<any>): any => {
        return dataArray.sort((a, b) => {
            const dateA = new Date(a[0]);
            const dateB = new Date(b[0]);
            return dateA.getTime() - dateB.getTime();
        });
    }


    const max = () => {
        if(equipment){
            let values: number[] = [];
            equipment.forEach(item =>
                item.value.forEach(element =>
                    values.push(truncateNumber(element)))
            )
            const valueMax = Math.max(...values);
            return truncateNumber(valueMax + (valueMax * 0.15));
        }
        return 0;
    }

    // return the group
    const handleOnGroupByTypeOfPeriod = (valueTypePeriod: string) => {
        if(valueTypePeriod.includes('day')){
            return 'Horário';
        } else if(valueTypePeriod.includes('week')) {
            return 'Dia';
        }
    }

    const minDate = (minDate:string) : string | undefined => {
        if(!isDay() || !minDate) return undefined;
        const min = new Date(minDate);
        min.setHours(4, 0, 0, 0); // 4:00
        return format(min, 'yyyy-MM-dd HH:mm:ss.S')
    }

    const maxDate = (maxDate:string) : string | undefined => {
        if(!isDay() || !maxDate) return undefined;
        const max = new Date(maxDate);
        max.setHours(20, 0, 0, 0);
        return format(max, 'yyyy-MM-dd HH:mm:ss.S')
    }

    const hoursToMilliseconds = (hours:number): number => {
        return hours * 60 * 60 * 1000
    };

    const findTransformerTemperatureDataByPeriod = () => {
        if(period.groupBy){
            setXAxis({
                name: handleOnGroupByTypeOfPeriod(period.groupBy),
                type: 'time',
                minInterval: period.groupBy.valueOf() === "day" ? hoursToMilliseconds(3) : hoursToMilliseconds(24),
                min: minDate(equipment[0].dateTime[0]),
                max:maxDate(equipment[0].dateTime[0]),
                axisLabel: {
                    formatter: period.groupBy.valueOf() === "day" ? '{HH}:{mm}' : '{dd}/{MM}'
                },
                boundaryGap: true,
                nameLocation: "middle",
                nameGap: 25
            });
        }
        let dataSeries: Array<SeriesOption> = [];

        equipment.map((item,index) => {
            const dataArray = item.dateTime.map((date, index) => item.value[index] != null ? [date, truncateNumber(item.value[index], 2)] : [date,'-'])
            const newDataArray = sortDataArray(dataArray)
            dataSeries.push({
                    name: `Relé de Trafo - ` + item.teleObjectConfigName.split(" ").slice(-2).join(" "),
                    data: newDataArray,
                    type: 'line',
                    smooth: true
                }
            )
        })
        setSeries(dataSeries)
    }

    useEffect(() => {
        dispatch(reset())
        setSeries([])
        setEquipment([])
    },[skidId])

    useEffect(() => {
        if(period && checkDiffDays(period.toDateTime, period.fromDateTime)){
            dispatch(getEntities({period: period, powerPlant: powerPlant, skidId: skidId}))
        }
    },[period, skidId])

    useInterval(() => {
        if(period && checkDiffDays(period.toDateTime, period.fromDateTime)){
            dispatch(getEntities({period: period, powerPlant: powerPlant, skidId: skidId}))
        }
    }, 60000) // 5 minutes

    useEffect(() => {
        if(entities && entities.length > 0){
            entities.forEach(entity => {
                setShowGraph(entity.dateTime.length > 0);
            })
            setEquipment(entities)
        }else{
            setEquipment([])
        }
    }, [entities]);

    useEffect(() => {
        if(equipment && equipment.length > 0){
            findTransformerTemperatureDataByPeriod()
        }else {
            dispatch(reset())
            setSeries([])
        }
    },[equipment])

    const handleCsvDownload = () => {
        let apiUrl = 'api/report/tele-objects/csv';

        const params = {
            teleObjectsId: equipment?.map(item => item.teleObjectId),
            powerStationsId: [powerPlant],
            skidIds: [skidId],
            startDateTime: LocalDateTimeFormatISO(period?.fromDateTime),
            endDateTime: LocalDateTimeFormatISO(period?.toDateTime)
        };

        downloadFilePostMapping(apiUrl, params, 'relatorio_telemedidas.csv', () => {
                enqueueSnackbar("Sucesso ao baixar o CSV", {variant: 'success'});
            },
            (error) => {
                enqueueSnackbar("Erro ao baixar o arquivo, tente mudar o Range de datas!", {variant: "error"});
            }
        );

    }

    const handleExcelDownload = () => {

        let apiUrl = 'api/report/tele-objects/excel';

        const params = {
            teleObjectsId: equipment?.map(item => item.teleObjectId),
            powerStationsId: [powerPlant],
            skidIds: [skidId],
            startDateTime: LocalDateTimeFormatISO(period?.fromDateTime),
            endDateTime: LocalDateTimeFormatISO(period?.toDateTime)
        };

        downloadFilePostMapping(apiUrl, params, 'relatorio_telemedidas.xlsx', () => {
                enqueueSnackbar("Sucesso ao Baixar o Excel", {variant: 'success'})
            },
            (error) => {
                enqueueSnackbar("Erro ao baixar o arquivo, tente mudar o Range de datas!", {variant: "error"});
            }
        );
    }

    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    formatter: function (params: any) {
                        const formatDate = period.groupBy.valueOf() === "week" ? "dd/MM" : "HH:mm"

                        if (params.axisDimension === 'x') {
                            return format(new Date(params.value), formatDate);
                        }
                        if (params.axisDimension === 'y') {
                            return truncateNumber(params.value,2);
                        }
                    }
                }
            },
            formatter: callback
        },
        toolbox: {
            right: 38,
            top: -8,
            itemSize: 15,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {}
            }
        },
        dataZoom: [
            {
                type: 'inside',
                throttle: 50
            },
        ],
        grid: {
            containLabel: true,
            top: 10,
            bottom: 45
        },
        legend: {
            type:'scroll',
            orient: "horizontal",
            right: '10%',
            bottom: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
        xAxis: xAxis,
        yAxis:
            {
                type: 'value',
                name: '°C',
                max: max(),
                interval: truncateNumber(max()/4),
                nameLocation: "middle",
                nameGap: 60,
                min: 0,
            }
        ,
        series: series
    }

    return (
        <ACard
            key={`chart-skid-transformer-${openSubMenu}`}
            headerControlsPosition={"header"}
            styleProps={{
                cardStyle: { height: 445 },
                contentStyle: { marginTop: 15, height: 306 }
            }}
            title="Temperatura do Transformador"
            headerControls={
                <>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        hideGroup={false}
                        periodInterval={{
                            day: {date: period.toDateTime, startTime: "00:00:00", endTime: "23:59:59"},
                            week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6}
                        }}
                        disabled={loading}
                        period={period}
                        onChange={setPeriod}
                        groupByOptions={["day", "week"]}
                    />
                </>
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //         />
            //     </>
            // }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                    <IconButton disabled={!isAuthorizedToSearchByCsv}>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20} onClick={handleCsvDownload}/>
                    </IconButton>
                    <IconButton disabled={!isAuthorizedToSearchByExcel}>
                        <FontAwesomeIcon icon={faFileExcel} fontSize={20} onClick={handleExcelDownload}/>
                    </IconButton>
                </APopoverIcon>
            }
        >
            {
                loading ?
                    <Skeleton animation="wave" height={289} variant="rounded" width={"100%"} />
                    :
                    (equipment && equipment.length > 0 && series && series.length > 0 && xAxis && showGraph) ?
                        <AChartX
                            option={option}
                            height={289}
                            width={"100%"}
                            loading={false}
                            theme={theme.palette.mode}
                        />
                        :
                        <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
            }
        </ACard>
    )
}

export default TransformerTemperatureChart;