import {ReactElement, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Button, Typography, useTheme} from "@mui/material";
import {
    isDashboardOverviewActivePowerPage,
    isDashboardOverviewAlarmsPage,
    isDashboardOverviewChartsPage,
    isDashboardOverviewLayoutPage,
    isDashboardOverviewMapPage,
    isDashboardOverviewSynopticPage,
    isDashboardPowerPlantAlarmsPage,
    isDashboardPowerPlantChartsPage, isDashboardPowerPlantInvertersPage,
    isDashboardPowerPlantLayoutPage, isDashboardPowerPlantOverviewPage,
    isDashboardPowerPlantPage,
    isDashboardPowerPlantSkidAlarmsPage,
    isDashboardPowerPlantSkidChartsPage,
    isDashboardPowerPlantSkidLayoutPage,
    isDashboardPowerPlantSKidPage,
    isDashboardPowerPlantTrackersPage, SystemRoutes
} from "../../../Utils/RouteUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import {useAppSelector} from "../../../Config/Hooks";
import {Period, PeriodSelector} from "@atiautomacao/ati-ui-library";
import {useDispatch} from "react-redux";
import {setPeriodSynoptic} from "../Synoptic/SynopticChartReducer";
import {setPeriodTrackers} from "../Powerplant/Trackers/TrackersReducer";
import {checkDiffDays} from "../../../Utils/DataUitils";
import { hasPermission } from "../../../Shared/Auth/AuthenticationUtil";
import { AUTHORITIES } from "../../../Config/Constants";

const getLayoutUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_LAYOUT
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_LAYOUT;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_LAYOUT;
}

const getChartUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_CHARTS
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_CHARTS;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_CHARTS;
}

const getAlarmsUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_ALARMS
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_ALARMS;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_ALARMS;
}

const getTrackersUrl = (): string => {
    return SystemRoutes.DASHBOARD_POWER_PLANT_TRACKERS;
}
const getInvertersUrl = (): string => {
    return SystemRoutes.DASHBOARD_POWER_PLANT_INVERTER;
}

const DashboardHeaderMenuMediumLargeScreens = (): ReactElement => {
    const theme = useTheme();
    const location = useLocation();
    const headerMenu = useAppSelector((state: any) => state.dashboardMenu.header);
    const dispatch = useDispatch();
    const { selectedPowerPlant } = useAppSelector((state) => state.dashboard);

    const style = {
        linkItem: {
            marginLeft: 5,
            marginRight: 5,
            color: theme.palette.primary.main,
            fontWeight: "500"
        },
        selectedLinkItem: {
            textDecoration: 'none',
        }
    }

    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToMap = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_MAP
    ]);
    const isAuthorizedToChart = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_CHART
    ]);
    const isAuthorizedToAlarm = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_ALARM
    ]);
    const isAuthorizedToSynoptic = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SYNOPTIC
    ]);
    const isAuthorizedToActivePower = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_ACTIVE_POWER
    ]);

  
    const isAuthorizedToPsChart = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION , AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_CHART
    ]);
    const isAuthorizedToPsAlarm = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION , AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_ALARM
    ]);
    const isAuthorizedToPsInverter = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION , AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_INVERTER
    ]);
    const isAuthorizedToPsTracker = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION , AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_TRACKER
    ]);
    const isAuthorizedToPsEquipment = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION , AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_EQUIPMENT
    ]);
    const isAuthorizedToPsPlant = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_POWER_STATION , AUTHORITIES.VIEW_DASHBOARD_POWER_STATION_PLANT_LAYOUT
    ]);


    const isAuthorizedToSkidChart = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID, AUTHORITIES.VIEW_DASHBOARD_CHART
    ]);
    const isAuthorizedToSkidAlarm = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID, AUTHORITIES.VIEW_DASHBOARD_ALARM
    ]);
    const isAuthorizedToSkidPlant = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_DASHBOARD, AUTHORITIES.VIEW_DASHBOARD_SKID, AUTHORITIES.VIEW_DASHBOARD_SKID_PLANT_LAYOUT
    ]);
    
    const [layoutUrl, setLayoutUrl] = useState(getLayoutUrl());
    const [chartUrl, setChartUrl] = useState(getChartUrl());
    const [alarmsUrl,setAlarmsUrl] = useState(getAlarmsUrl());
    const [trackersUrl, setTrackersUrl] = useState(getTrackersUrl());

    const [isTrackersDisabled, setIsTrackerDisabled] = useState(true);
    const [isInvertersDisabled, setIsInvertersDisabled] = useState(true);

    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const initialPeriodTrackers: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [periodDataTrackers, setPeriodDataTrackers] = useState<Period>(initialPeriodTrackers);

    useEffect(() => {
        let hasTrackers = false;
        let hasInverters = false;
        if(selectedPowerPlant){
            selectedPowerPlant.skidList.forEach((skid: any) => {
                hasTrackers = skid.equipmentList.some((eqp: any) => eqp.equipmentType && eqp.equipmentType?.id === 8) ||
                    selectedPowerPlant.equipmentList.some((eqpPs: any) => eqpPs.equipmentType && eqpPs.equipmentType?.id === 8);
                hasInverters = skid.equipmentList.some((eqp: any) => eqp.equipmentType && eqp.equipmentType?.id === 2) ||
                    selectedPowerPlant.equipmentList.some((eqpPs: any) => eqpPs.equipmentType && eqpPs.equipmentType?.id === 2);
            });
        }
        setIsTrackerDisabled(!hasTrackers);
        setIsInvertersDisabled(!hasInverters);
    }, [selectedPowerPlant]);

    useEffect(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(setPeriodSynoptic(period));
        }
    }, [period]);

    useEffect(() => {
        if(checkDiffDays(periodDataTrackers.toDateTime, periodDataTrackers.fromDateTime) > 0){
            dispatch(setPeriodTrackers(periodDataTrackers));
        }
    }, [periodDataTrackers]);

    return (
        <>
            {headerMenu.buttons.chartsButton ?
                <Link to={isAuthorizedToChart || isAuthorizedToPsChart || isAuthorizedToSkidChart ? getChartUrl() : '#'}>
                    <Button variant={"text"}
                            disabled={isAuthorizedToChart || isAuthorizedToPsChart || isAuthorizedToSkidChart ? (isDashboardOverviewChartsPage() || isDashboardPowerPlantChartsPage() || isDashboardPowerPlantSkidChartsPage()): true}
                            style={isAuthorizedToChart || isAuthorizedToPsChart || isAuthorizedToSkidChart ? style.linkItem : {}} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Gráficos
                    </Button>
                </Link> : null }

            {headerMenu.buttons.alarmsButton ?
                <Link to={isAuthorizedToAlarm || isAuthorizedToPsAlarm || isAuthorizedToSkidAlarm ? getAlarmsUrl() : '#'}>
                    <Button variant={"text"}
                            disabled={isAuthorizedToAlarm || isAuthorizedToPsAlarm || isAuthorizedToSkidAlarm ? (isDashboardOverviewAlarmsPage() || isDashboardPowerPlantAlarmsPage() || isDashboardPowerPlantSkidAlarmsPage()) : true}
                            style={isAuthorizedToAlarm || isAuthorizedToPsAlarm || isAuthorizedToSkidAlarm ? style.linkItem : {}} sx={{textTransform:"capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar} />}>
                        Alarmes
                    </Button>
                </Link> : null }


            {headerMenu.buttons.mapButton && !isDashboardPowerPlantPage() ?
                <Link to={isAuthorizedToMap ? `/dashboard/map` : '#'}>
                    <Button variant={"text"} disabled={isAuthorizedToMap ? isDashboardOverviewMapPage() : true}
                            style={isAuthorizedToMap ? style.linkItem : {}} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Mapa
                    </Button>
                </Link> : null}

            { headerMenu.buttons.synopticButton ?
                <Link to={isAuthorizedToSynoptic ? `/dashboard/synoptic` : '#'}>
                    <Button variant={"text"} disabled={isAuthorizedToSynoptic ? isDashboardOverviewSynopticPage() : true}
                            style={isAuthorizedToSynoptic ? style.linkItem : {}} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Sinóptico
                    </Button>
                </Link> : null}

            { headerMenu.buttons.activePowerButton ?
                <Link to={isAuthorizedToActivePower ? `/dashboard/active-power` : '#'}>
                    <Button variant={"text"} disabled={isAuthorizedToActivePower ? isDashboardOverviewActivePowerPage() : true}
                            style={isAuthorizedToActivePower ? style.linkItem : {}} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Potência Ativa
                    </Button>
                </Link> : null}

            {headerMenu.buttons.layoutButton && !isDashboardOverviewLayoutPage() ?
                <Link  to={isAuthorizedToPsPlant || isAuthorizedToSkidPlant ? getLayoutUrl() : '#'}>
                    <Button variant={"text"}
                            style={isAuthorizedToPsPlant || isAuthorizedToSkidPlant ? style.linkItem : {}}
                            disabled={isAuthorizedToPsPlant || isAuthorizedToSkidPlant ? (isDashboardPowerPlantSkidLayoutPage() || isDashboardPowerPlantLayoutPage()) : true}
                            sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Unifilar
                    </Button>
                </Link>: null }

            {headerMenu.buttons.equipmentsButton && !isDashboardPowerPlantSKidPage() ?
                <Link to={isAuthorizedToPsEquipment ? (`/dashboard/power-plant${location.pathname.includes("skid") ? "/skid": ""}/equipments`) : '#'}>
                    <Button variant={"text"}
                            style={isAuthorizedToPsEquipment ? style.linkItem : {}} sx={{textTransform: "capitalize"}}
                            disabled={isAuthorizedToPsEquipment ? location.pathname.includes("equipments") : true}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        <Typography>Equipments</Typography>
                    </Button>
                </Link> : null}

            {headerMenu.buttons.trackersButton ?
                <Link to={isAuthorizedToPsTracker ? (isTrackersDisabled ? '#' : getTrackersUrl()) : '#'}>
                    <Button
                        variant={"text"}
                        sx={{textTransform: "capitalize"}}
                        style={isAuthorizedToPsTracker ? isTrackersDisabled ? {} : style.linkItem : {}}
                        disabled={isAuthorizedToPsTracker ? location.pathname.includes("trackers") || isTrackersDisabled : true}
                        startIcon={<FontAwesomeIcon icon={faChartBar}/>}
                    >Trackers</Button>
                </Link>: null }

            {headerMenu.buttons.invertersButton ?
                <Link to={ isAuthorizedToPsInverter ? (isInvertersDisabled ? '#' : getInvertersUrl()) : '#'}>
                    <Button
                        variant={"text"}
                        sx={{textTransform: "capitalize"}}
                        style={isAuthorizedToPsInverter ? isInvertersDisabled ? {} : style.linkItem : {}}
                        disabled={isAuthorizedToPsInverter ? (location.pathname.includes("inverter") || isInvertersDisabled) : true}
                        startIcon={<FontAwesomeIcon icon={faChartBar}/>}
                    >Inversor</Button>
                </Link>: null
            }

            { isDashboardOverviewSynopticPage() ?
                <div style={{marginLeft: 'auto'}}>
                    <PeriodSelector
                        styleProps={{
                            base: {display: "flex", flexDirection: "row", alignItems: "center"},
                            toggleButtonGroup: { backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }
                        }}
                        mode="hybrid"
                        hideDatetime={false}
                        hideGroup={false}
                        periodInterval={{
                            week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                            month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                            year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
                            general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
                        }}
                        period={period}
                        onChange={setPeriod}
                        groupByOptions={["day", "week", "month", "year", "general"]}
                    />
                </div> : ""
            }

            { isDashboardPowerPlantTrackersPage() ?
                <div style={{marginLeft: 'auto'}}>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        hideGroup={false}
                        periodInterval={{
                            day: {date: periodDataTrackers.toDateTime, startTime: "04:00:00", endTime: "21:00:00"},
                        }}

                        period={periodDataTrackers}
                        onChange={setPeriodDataTrackers}
                        groupByOptions={["day"]}
                    />
                </div> : ""
            }

        </>
    );
}

export default DashboardHeaderMenuMediumLargeScreens;