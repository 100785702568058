import React, {MouseEvent, ReactElement, useCallback, useEffect, useState} from "react";

import {
    ACard,
    ADataGrid,
    ADataGridFilter,
    APopoverIcon,
    PagedSearchParams,
    Period,
} from "@atiautomacao/ati-ui-library";
import {
    Box, Button,
    LinearProgress,
    linearProgressClasses,
    Link,
    styled, TextField,
    Typography,
    } from "@mui/material";
import {checkDiffDays, extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {clear, Performance, searchEntities} from "./PerformanceAnalysisReducer";
import GeneratedInjectedChart from "./GeneratedInjectedChart";
import IrradianceChart from "./IrradianceChart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faFileCsv,
    faFileExcel,
    faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import {usePowerStationNavigation} from '../../../Shared/Hooks/usePowerStationNavigation';
import IconButton from "@mui/material/IconButton";
import {getIconColor} from "../../../Shared/Components/Layout/menu/helpers/getIconColor";
import {MenuItemStatus} from "../../../Shared/Components/Layout/menu/types/MenuItemStatus";
import {performanceAnalysisColumns} from "./PerformanceAnalysisConfig";
import useInterval from "../../../Shared/Hooks/useInterval";
import {hasPermission} from "../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../Config/Constants";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {subDays} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ActivePowerChart from "./ActivePowerChart";

const PerformanceAnalysisPage = () : ReactElement => {
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSearchByPdf = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_PDF_EXPORT, AUTHORITIES.EXPORT_PERFORMANCE_ANALYSIS, AUTHORITIES.PDF_EXPORT_PERFORMANCE_ANALYSIS
    ]);
    const isAuthorizedToSearchByCsv = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_CSV_EXPORT, AUTHORITIES.EXPORT_PERFORMANCE_ANALYSIS, AUTHORITIES.CSV_EXPORT_PERFORMANCE_ANALYSIS
    ]);
    const isAuthorizedToSearchByExcel = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.VIEW_EXCEL_EXPORT, AUTHORITIES.EXPORT_PERFORMANCE_ANALYSIS, AUTHORITIES.EXCEL_EXPORT_PERFORMANCE_ANALYSIS
    ]);

    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(new Date().setHours(0, 0, 0, 0)),
        toDateTime: new Date(new Date().setHours(23, 59, 59))
    };

    const [period, setPeriod] = useState<Period>(initialPeriod);

    const dispatch = useAppDispatch();
    const { entities, loading } = useAppSelector((state: RootState) => state.performanceAnalysis);
    const totalOfRecords = useAppSelector((state : RootState) => state.performanceAnalysis.totalOfRecords);
    const navigationToPowerStation = usePowerStationNavigation();

    const [rows, setRows] = useState<Array<any>>([]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(performanceAnalysisColumns), 0, 10));

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    },[]);

    const [fromDateTime, setFromDateTime] = useState<Date>(subDays(initialPeriod.fromDateTime, 7));
    const [toDateTime, setToDateTime] = useState<Date>(subDays(initialPeriod.toDateTime, 0));

    const statusColor = (status: MenuItemStatus) => {
        const color = getIconColor(status)
        return <div style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            margin: "10px",
            float: "left",
            background: color
        }} title={status}>
        </div>
    }

    const verifyProgress = (value: number) => {
        if (value >= 70 && value <= 95) {
            return "#a4f657"
        }else if (value >= 60 || value > 95) {
            return "#FFD700";
        }else if (value < 60 ) {
            return "#FF4500"
        }
    };

    const handleNavigation = (event: MouseEvent<HTMLElement>, powerPlantId: number): void => {
        event.preventDefault();
        navigationToPowerStation(powerPlantId);
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({        
        height: 20,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: verifyProgress(value ?? 0),
        },
    }));

    // @ts-ignore
    function LinearProgressWithLabel({value}) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <BorderLinearProgress variant="determinate" value={value}/>
                </Box>
                <Box sx={{ position: 'relative', minWidth: 35, right: '50%'}}>
                    <Typography variant="body2">{`${Math.round(value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    function irradianceSum (element: number) : number | string {
        return element ? parseFloat(element.toString()).toFixed(2) : " ";
    }
    function performanceRatio(element: number) : string{
        return element ? parseInt(element.toString()) + "%" : " ";
    }

    const GeneratedInjected = (generated:number, injected:number) => {
        return(
            <Box  >
                <Typography variant="subtitle1" fontSize={12} color={"#767676"}>Gerada: <label style={{color: "#000"}}>{generated ? `${generated.toFixed(2) + " MWh "}` : " "} </label></Typography>
                <Typography variant="subtitle1" fontSize={12} color={"#767676"}>Injetada: <label style={{color: "#000"}}>{injected ? `${injected.toFixed(2) + " MWh "}` : " "} </label></Typography>
            </Box>
        )
    }

    const structureRow = (entitiesValues: Array<Performance>) => {
        let entityAlarms: Array<any> = []
        entitiesValues.forEach( element => {
            entityAlarms.push({
                status: statusColor(element.status),
                powerStationName: <Link href="#" onClick={(event) => handleNavigation(event, element.powerStationId)} underline="hover">{element.powerStationName}</Link>,
                powerCapacityNominal: element.powerCapacityNominal ? parseInt(element.powerCapacityNominal.toString()) : " ",
                instantPower: <div style={{width: '90%', display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ActivePowerChart dataInstantPower={element.instantPowerList}/>
                    </Box>
                    <Box>{element.instantPower ? parseFloat(element.instantPower.toString()).toFixed(2) : " "}</Box>
                </div>,
                irradianceSum:  <div style={{width: '80%', display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center'}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        { element.irradianceList.length > 0 ?
                                            <IrradianceChart dataIrradiance={element.irradianceList}/>
                                            : ""
                                        }
                                    </Box>
                                    <Box>{irradianceSum(element.irradianceSum)}</Box>
                                </div>,

                generatedEnergySum: element.generatedEnergyList.length > 0 && element.injectedEnergyList.length > 0 &&
                    <div style={{width: '80%', display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center'}}>
                        <Box>
                            <GeneratedInjectedChart dataGenerated={element.generatedEnergyList} dataInjected={element.injectedEnergyList}/>
                        </Box>
                        <Box>
                            {GeneratedInjected(
                                element.generatedEnergySum,
                                element.injectedEnergySum
                            )}
                        </Box>
                    </div>,
                performanceRatio: <LinearProgressWithLabel value={element.performanceRatio || 0} /> ,
                availability: element.availability ? parseInt(element.availability.toString()) + " %" : " ",
                generatedEnergy: element.generatedEnergyList,
            });
        });
        setRows(entityAlarms);
    }

    useEffect(() => {
        let periodWeek: Period = {groupBy: "week", fromDateTime, toDateTime};
        dispatch(searchEntities({period: periodWeek, params: pagedSearchParams}));
    }, [pagedSearchParams, period])

    useInterval(() => {
        let periodWeek: Period = {groupBy: "week", fromDateTime, toDateTime};
        dispatch(searchEntities({period: periodWeek, params: pagedSearchParams}));
    }, 60000) // 5 minutes

    useEffect(() => {
        if(entities && entities.length > 0){
            structureRow(entities);
        }
    }, [entities])

    useEffect(() => {
        dispatch(clear());
        setRows([])
        let periodWeek: Period = {groupBy: "week", fromDateTime, toDateTime};
        dispatch(searchEntities({period: periodWeek, params: pagedSearchParams}));
    }, []);

    const onChangeHandleFromDateTimeChange = (newValue: Date | null) => {
        if (newValue) {
            setFromDateTime(newValue);
        }
    };

    const onChangeHandleToDateTimeChange = (newValue: Date | null | undefined) => {
        if (newValue) {
            setToDateTime(newValue);
        }
    };

    const handleDatePickerChange = () => {
        setPeriod({ fromDateTime, toDateTime, groupBy: 'week' });
    };

    return (
        <ACard
            title="Análise de Desempenho"
            styleProps={{
                headerStyle: {fontSize: 19}
            }}
            headerControlsPosition={"header"}
            headerControls={
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginRight: 2}}>
                        <Box sx={{paddingRight: 5, width: '200px'}}>
                            <DateTimePicker
                                label="De:"
                                renderInput={(params) => <TextField variant="standard" {...params} />}
                                value={fromDateTime}
                                ampm={false}
                                views={['day']}
                                inputFormat='dd/MM/yyyy'
                                onChange={(newValue) => onChangeHandleFromDateTimeChange(newValue)}
                            />
                        </Box>
                        <Box sx={{paddingRight: 5, width: '200px'}}>
                            <DateTimePicker
                                label="Até:"
                                renderInput={(params: any) => <TextField variant="standard" {...params} />}
                                value={toDateTime}
                                ampm={false}
                                views={['day']}
                                inputFormat='dd/MM/yyyy'
                                onChange={(newValue) => onChangeHandleToDateTimeChange(newValue)}
                            />
                        </Box>
                        <Button
                            onClick={handleDatePickerChange}
                            variant="contained"
                            sx={{padingLeft: '10px', height: '40px', minWidth: '40px', marginTop: '8px'}}
                        >
                            Filtrar
                        </Button>
                    </Box>
                </LocalizationProvider>
            }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFilePdf} fontSize={20}/>
                    </IconButton>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                    </IconButton>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFileExcel} fontSize={20}/>
                    </IconButton>
                </APopoverIcon>
            }
        >
            <ADataGrid
                hideSelection={true}
                hideFilters={true}
                size={"small"}
                columns={performanceAnalysisColumns}
                rows={rows}
                headerStyle={{ color: '#fff', textTransform: 'capitalize' }}
                page={pagedSearchParams.page}
                loading={loading}
                totalOfRecords={totalOfRecords || 0}
                rowsPerPage={pagedSearchParams.size}
                onFiltersChange={handleOnFiltersChange}
                showSortOptions={true}
            />
        </ACard>
    )

}

export default PerformanceAnalysisPage;