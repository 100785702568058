import {useQuery} from "react-query";
import axios from "axios";

const apiUrl = "api/managementReportSkid";


const fetchAllSkidsConcated = async () => {
    try {
        return await axios
            .get(apiUrl + `/skidsConcated`, {timeout: 30000})
            .then((res) => res.data);
    } catch (error : any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error("Error fetching data: " + error.message));
    }
};

export const ManagementReportSkidsConcated = () => {
    return useQuery({
        queryKey: ["data"],
        queryFn: () => {
            return fetchAllSkidsConcated();
        }
    });
}

export interface SubReportHeader {
    id: number;
    generatedEnergyName: string | null;
    injectedEnergyName: string | null;
    expectedInjectedEnergyName: string | null;
    yieldFieldName: string | null;
    expectedYieldName: string | null;
    capacityFactorName: string | null;
    irradianceName: string | null;
    expectedIrradianceName: string | null;
    satelitalIrradianceName: string | null;
    availabilityName: string | null;
    totalAvailabilityName: string | null;
    temperatureName: string | null;
    performanceRatioName: string | null;
    expectedPerformanceRatioName: string | null;
    weekEstimatedInjEnergyName: string | null;
    monthEstimatedInjEnergyName: string | null;
    yearEstimatedInjEnergyName: string | null;
    generationLossesName: string | null;
    generatedEnergyDeviationName: string | null;
    injectedEnergyDeviationName: string | null;
    estimatedInjEnergyDeviationName: string | null;
    yieldDeviationName: string | null;
    irradiationDeviationName: string | null;
    performanceRatioDeviationName: string | null;
    performanceRatioRawName: string | null;
    operationAvailabilityName: string | null;
    yieldRateName: string | null;
}

const fetchSubReportHeader = async (): Promise<SubReportHeader> =>{
    return await axios
        .get<SubReportHeader>('/api/pdf/find-header',{timeout:30000})
        .then((res) => res.data)
}

export const SubReportHeaderReactQuery = () => {
    return useQuery({
        queryKey: ['data-sub-report-header'],
        queryFn: () => fetchSubReportHeader()
    })
}