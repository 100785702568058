import React, {FunctionComponent} from "react";
import {AChartX} from "@atiautomacao/ati-ui-library";
import {useTheme} from "@mui/material";
import {format, isValid, parseISO} from "date-fns";
import {Chart} from "./PerformanceAnalysisReducer";

const ActivePowerChart: FunctionComponent<{dataInstantPower: Array<Chart>}> = ({dataInstantPower}) =>  {
    const theme = useTheme();
    const style = {
        text: {
            fontSize: 16,
        }
    };

    const chartOption: any = {
        yAxis: [{
            name: '',
            min: 0,
            max: Math.max(...dataInstantPower.map(item => item.value ? parseInt(item.value.toString()) : item.value)),
            interval: Math.max(...dataInstantPower.map(item => item.value ? parseInt(item.value.toString()) : item.value)) / 2,
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            }
        }],
        xAxis: {
            type: 'category',
            boundaryGap: true,
            name: '',
            nameLocation: 'center',
            nameGap: 20,
            data: dataInstantPower.map(item => {
                const date = parseISO(item.dateTime);
                if (isValid(date)) {
                    return format(date, 'dd/MM');
                }
                return item.dateTime;
            }),
            offset: 5,
            axisLabel: {
                show: false,
                inside: false,
                showMinLabel: true,
                showMaxLabel: true,
                align: 'center',
                // interval: "auto",
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        series: [{
            name: 'Potência',
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: dataInstantPower.map(item => item.value ? parseInt(item.value.toString()) : "-"),
            areaStyle: {},
        }],
        legend: {
            show: false,
        },
        grid: {
            containLabel: true,
            top: 0,
            bottom: 10,
        },
        animationDuration: 2000,
        color: ['rgb(25, 118, 210)'],
        tooltip: {
            trigger: 'axis',
            textStyle: {
                fontSize: style.text.fontSize
            },
            valueFormatter: (value: any) => `${value} kW`,
        }

    };

    return (
        <>
            {
                dataInstantPower.length > 0 &&
                    <AChartX
                        height={40}
                        option={chartOption}
                        theme={theme.palette.mode}
                    />
            }
        </>
    )

}

export default ActivePowerChart;