import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {ACard, Period, PeriodSelector, Skeleton} from "@atiautomacao/ati-ui-library";
import {ABarLine} from "@atiautomacao/ati-ui";
import {GeneratedEnergy} from "../../DashboardPowerPlantCharts";
import {format} from "date-fns";
import {ptBR} from "date-fns/locale";
import {truncateNumber} from "../../../../../Utils/NumberUtil";
import {isArray} from "lodash";
import {useSnackbar} from "notistack";
import DataNotFound from "../../../../../Shared/Components/DataNotFoundMessage";
import {FindGenerationInverterByDailReactQuery} from "../InverterReactQuery";
import {useAppSelector} from "../../../../../Config/Hooks";

export const handleDateTimeFormat = (generatedEnergyData: GeneratedEnergy[], period: Period):Array<any> => {
    try {
        return generatedEnergyData.map((generation) => {
            if (!generation.dateTime) {
                return "Data Ausente";
            }
            if (period.groupBy === "year" && RegExp(/^\d{4}-\d{2}$/).exec(generation.dateTime)) {
                const [year, month] = generation.dateTime.split('-');
                const dateComplete = new Date(parseInt(year), parseInt(month) - 1, 1);
                return format(dateComplete, 'MMM', { locale: ptBR });
            } else if (period.groupBy === "month" || period.groupBy === "none") {
                return format(new Date(generation.dateTime + ' '), 'dd/MM', { locale: ptBR });
            } else {
                return "";
            }
        });
    } catch (error) {
        console.error("Erro ao formatar datas:", error);
        return [];
    }
}

const GenerationEnergyChart: FunctionComponent<{ powerPlantId: number, equipmentId: number, groupBy: string, unity: string}> = ({powerPlantId,equipmentId, groupBy, unity}) => {
    const mutate = FindGenerationInverterByDailReactQuery();
    const [generatedEnergy,setGeneratedEnergy]  = useState<GeneratedEnergy[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    const getMaxYAxisValue = useCallback((): number => {
        const values = generatedEnergy.map(
            (generation: GeneratedEnergy) => generation.generatedEnergy ? truncateNumber(generation.generatedEnergy) : generation.generatedEnergy)

        return values.length > 0 ? Math.max(...values) : 0;
    }, [generatedEnergy]);

    const [period, setPeriod] = useState<Period>(() => {
        const toDateTime = new Date();
        const fromDateTime = new Date();

        return {
            groupBy : groupBy === "year" ? "year" : "month" ,
            fromDateTime,
            toDateTime
        }
    });

    useEffect(() => {
        if(equipmentId && powerPlantId){
            const groupBy = period.groupBy === "year" ? "month" : "day";
            mutate.mutate(
                {
                    startDateTime: period.fromDateTime,
                    endDateTime: period.toDateTime,
                    groupBy: groupBy,
                    powerStationId: powerPlantId,
                    equipmentId: equipmentId
                },
                {
                    onSuccess: (data) => {
                        setGeneratedEnergy(data?.data || []);
                    },
                    onError: (error: any) => {
                        enqueueSnackbar(`Erro ao carregar dados de geração de energia: ${error.message}`, { variant: "error" });
                    }
                }
            );
        } else {
            setGeneratedEnergy([]);
        }

    }, [period, equipmentId, powerPlantId]);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);

    let content;
    if (!mutate.isSuccess) {
        content = <Skeleton animation="wave" height={289} variant="rounded" width="100%" />;
    } else if (isArray(generatedEnergy) && generatedEnergy.length > 0) {
        content = (
            <ABarLine
                key={`chart-power-generation-${openSubMenu}-${groupBy}`}
                legendRight={115}
                height={289}
                xAxis={{ data: handleDateTimeFormat(generatedEnergy, period) }}
                yAxis={[
                    {
                        name: unity,
                        max: truncateNumber(getMaxYAxisValue() + getMaxYAxisValue() * 0.15, 0),
                        min: 0,
                        interval: truncateNumber(getMaxYAxisValue() / 5),
                        nameGap: 70,
                        namePosition: 'middle'
                    },
                    {
                        name: 'Wh/m²',
                        max: truncateNumber(Math.max(
                            ...generatedEnergy.map(value => value.irradiance),
                        )),
                        min: 0,
                        interval: truncateNumber(Math.max(
                            ...generatedEnergy.map(value => value.irradiance),
                        ) / 5),
                        nameGap: 70,
                        namePosition: 'middle'
                    }
                ]}
                series={[
                    {
                        name: 'Energia Gerada',
                        type: 'bar',
                        unity: unity,
                        data: generatedEnergy.map((generation: GeneratedEnergy) => generation.generatedEnergy ? truncateNumber(generation.generatedEnergy, 2) : "-")
                    },
                    {
                        name: 'Irradiação',
                        type: 'line',
                        unity: 'Wh/m²',
                        yAxisIndex: 1,
                        data: generatedEnergy.map((generation: GeneratedEnergy) => generation.irradiance ? truncateNumber(generation.irradiance, 2) : "-")
                    }
                ]}
            />
        );
    } else {
        content = <DataNotFound boxStyle={{ height: 289, width: '100%' }} />;
    }

    return (
        <ACard
            styleProps={{
                cardStyle: { height: 445 },
                contentStyle: { padding: 0 }
            }}
            title={period.groupBy === "month" ? "Geração  Diária" : "Geração Mensal"}
            headerControls={
                <PeriodSelector
                    styleProps={{ base: { flexDirection: "row", display: "flex", alignItems: "center" } }}
                    mode="hybrid"
                    hideDatetime={false}
                    hideGroup={false}
                    period={period}
                    periodInterval={{
                        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12}
                    }}
                    groupByOptions={[groupBy === "year" ? "year" : "month"]}
                    onChange={setPeriod}
                />
            }

        >
            {content}
        </ACard>
    );
}

export default GenerationEnergyChart;